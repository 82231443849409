import { Link } from "react-router-dom";
import logo from "./assets/logo.png";

const Header = () => {
  return (
    <header>
      <div className="site-navigation" id="mainmenu-area">
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <Link className="navbar-brand" to="/">
              <img
                src={logo}
                alt="logo"
                className="img-fluid"
                style={{ width: "130px" }}
              />
            </Link>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
