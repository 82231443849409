import { Button, Spinner } from "react-bootstrap";

const SubmitButton = ({ loading, disabled, buttonText, ...props }) => {
  return (
    <Button
      size="lg"
      className="w-100 mb-3"
      disabled={loading || disabled}
      {...props}
    >
      {loading ? (
        <Spinner as="span" animation="border" size="sm" role="status" />
      ) : (
        `${buttonText}`
      )}
    </Button>
  );
};

export default SubmitButton;
