import React, { useState } from "react";
import { Form, Modal as ModalComponent } from "react-bootstrap";
import { useForm } from "react-hook-form";
import SubmitButton from "./SubmitButton";
import axios from "axios";

const FormModal = ({ show, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const methods = useForm({
    mode: "onTouched",
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      telegram: "",
      phone: "",
    },
  });

  const {
    getValues,
    register,
    formState: { isValid, errors },
  } = methods;

  const onSubmit = async () => {
    const fields = getValues();

    const { email, firstName, lastName, phone, telegram } = fields;

    try {
      setLoading(true);

      await axios.post("https://ebook-api-q5zj.onrender.com/api/submission", {
        firstName,
        lastName,
        email,
        phone,
        telegram,
      });

      setLoading(false);
      setSubmitted(true);
      setTimeout(() => {
        handleClose();
        setSubmitted(false);
      }, 4000);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  return (
    <ModalComponent show={show} onHide={handleClose} centered>
      <ModalComponent.Header>
        <h4 className="card-header-title">
          Receive your FREE Guide via email by completing the form below:
        </h4>
        {/* <CloseButton onClick={handleClose} /> */}
      </ModalComponent.Header>
      <ModalComponent.Body className="overflow-auto d-flex flex-column flex-grow-1">
        <Form>
          <div className="form-group">
            <Form.Label htmlFor="firstName">First Name</Form.Label>
            <Form.Control
              type="text"
              id="firstName"
              placeholder="John"
              {...register("firstName", { required: true })}
            />
            {errors?.firstName && (
              <p className="text-danger">This field is required</p>
            )}
          </div>
          <div className="form-group">
            <Form.Label htmlFor="lastName">Last Name</Form.Label>
            <Form.Control
              type="text"
              id="lastName"
              placeholder="Doe"
              {...register("lastName", { required: true })}
            />
            {errors?.lastName && (
              <p className="text-danger">This field is required</p>
            )}
          </div>
          <div className="form-group">
            <Form.Label htmlFor="email">Email</Form.Label>
            <Form.Control
              type="email"
              id="email"
              placeholder="johndoe@gmail.com"
              {...register("email", { required: true })}
            />
            {errors?.email && (
              <p className="text-danger">This field is required</p>
            )}
          </div>
          <div className="form-group">
            <Form.Label htmlFor="telegram">Telegram Username</Form.Label>
            <Form.Control
              type="text"
              id="telegram"
              placeholder="johndoe"
              {...register("telegram", { required: false })}
            />
          </div>
          <div className="form-group">
            <Form.Label htmlFor="phone">Phone Number</Form.Label>
            <Form.Control
              type="tel"
              id="phone"
              placeholder="+1234567890"
              {...register("phone", { required: true })}
            />
            {errors?.phone && (
              <p className="text-danger">This field is required</p>
            )}
          </div>
          {submitted && (
            <p className="text-success">
              Thank you for your submission. The ebook will be sent to your
              email address.
            </p>
          )}
          {error && <p className="text-danger">Something went wrong</p>}

          <SubmitButton
            buttonText="Submit"
            loading={loading}
            onClick={onSubmit}
            disabled={!isValid || loading}
          ></SubmitButton>
        </Form>
      </ModalComponent.Body>
    </ModalComponent>
  );
};

export default FormModal;
