import Header from "../Header";
import cover from "../assets/cover.JPG";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useState } from "react";
import FormModal from "../components/FormModal";

const Home = () => {
  const [showFormModal, setShowFormModal] = useState(false);

  const onButtonClicked = () => {
    setShowFormModal(true);
  };

  return (
    <>
      {showFormModal && (
        <FormModal
          show={showFormModal}
          handleClose={() => {
            setShowFormModal(false);
          }}
        />
      )}

      <div id="top-header">
        <Header />
        <section className="banner pb-80">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-12 col-lg-6">
                <div className="banner-content">
                  <h1>
                    <span style={{ color: "#018100" }}>
                      Green Investing 101:
                    </span>
                    <br /> A Beginnner's Handbook to Sustainable Income
                  </h1>

                  <p>
                    This FREE guide offers actionable insights, tips and
                    strategies to get started in Green investing, allowing you
                    to generate passive income while making positive
                    environmental and social impacts.
                  </p>
                  <button className="btn btn-main" onClick={onButtonClicked}>
                    Get Your Free Guide
                  </button>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="banner-img">
                  <img src={cover} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
            {/* / .row */}
          </div>
          {/* / .container */}
        </section>

        <section className="pt-100 center-about bg-grey">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-heading text-center">
                  <h2>
                    Be part of the movement for a brighter,{" "}
                    <span style={{ color: "#018100" }}>greener</span> future
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="about-section section-padding bg-grey" id="about">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <OwlCarousel
                  className="testimonials-slides owl-carousel owl-theme"
                  loop
                  dots
                  nav={false}
                  center
                  autoplayHoverPause
                  autoplay
                  autoplayTimeout={6000}
                  responsiveClass
                  margin={10}
                  responsive={{
                    0: {
                      items: 1,
                    },
                    576: {
                      items: 1,
                    },
                    768: {
                      items: 1,
                    },
                    1000: {
                      items: 1,
                    },
                    1200: {
                      items: 1,
                    },
                  }}
                >
                  <div className="about-img">
                    <img src="/images/1.png" alt="" className="img-fluid" />
                  </div>
                  <div className="about-img">
                    <img src="/images/2.png" alt="" className="img-fluid" />
                  </div>
                  <div className="about-img">
                    <img src="/images/3.png" alt="" className="img-fluid" />
                  </div>
                </OwlCarousel>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="section-heading">
                  <h3 className="heading-title">About The Guide</h3>
                  <p>
                    Start your Green Investing Journey with our easy-to-follow
                    guide with sections on:
                  </p>
                </div>
                <div className="about-text-block">
                  <i className="bi bi-spark" />
                  <h4>Fundamentals of Green Investing</h4>
                </div>
                <div className="about-text-block">
                  <i className="bi bi-steps" />
                  <h4>Top Green Investment Options</h4>
                </div>
                <div className="about-text-block">
                  <i className="bi bi-article" />
                  <h4>Action steps to managing a profitable green portfolio</h4>
                </div>
                <div className="about-text-block">
                  <i className="bi bi-ellipsis-horizontal" />
                  <h4>And more!</h4>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="chapter-section section-padding bg-white"
          id="chapters"
        >
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-8">
                <div className="center-heading mb-60">
                  <h3 className="heading-title">What you will learn</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="chapter-list">
                  <div className="chapter-item">
                    <h4>
                      <span>Page 1</span> Introduction to Green Investing
                    </h4>
                  </div>
                  <div className="chapter-item">
                    <h4>
                      <span>Page 2</span> Why Green Investing is Important
                    </h4>
                  </div>
                  <div className="chapter-item">
                    <h4>
                      <span>Page 3 </span> Environmental Challenges and the Role
                      of Green Investing
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="chapter-list">
                  <div className="chapter-item">
                    <h4>
                      <span>Page 4</span> Benefits of Green Investing
                    </h4>
                  </div>
                  <div className="chapter-item">
                    <h4>
                      <span>Page 5-6</span> Types of Green Investments
                    </h4>
                  </div>
                  {/* <div className="chapter-item">
                    <h4>
                      <span>Page 6</span> Types of Green Investments contd.
                    </h4>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="chapter-list border-right-0">
                  <div className="chapter-item">
                    <h4>
                      <span>Page 7-8</span> How to get started with Green
                      Investing
                    </h4>
                  </div>
                  {/* <div className="chapter-item">
                    <h4>
                      <span>Page 8 </span> How to get started with Green
                      Investing contd.
                    </h4>
                  </div> */}
                  <div className="chapter-item">
                    <h4>
                      <span>Page 9 </span> Conclusion
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="book-preview section-padding" id="topics">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="section-heading center-heading text-center mb-60">
                  <h3 className="heading-title text-white">
                    Who this guide benefits the most
                  </h3>
                  <p className="text-white">
                    In today's world, caring about the environment means more
                    than just concern - it's about making a difference. This
                    guide provides a practical plan for those ready to make an
                    impact through their finances.
                    <br /> Whether you're an entrepreneur, environmental
                    enthusiast or simply someone who wants a safe and healthy
                    planet, this guide is essential for starting your journey.
                  </p>
                  <button className="btn btn-main" onClick={onButtonClicked}>
                    Get Your Free Guide
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="testimonial section-padding bg-grey"
          id="testimonial"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="section-heading center-heading text-center mb-60">
                  <h3 className="heading-title">Testimonials</h3>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <OwlCarousel
                  className="testimonials-slides owl-carousel owl-theme"
                  loop
                  dots
                  nav={false}
                  center
                  autoplayHoverPause
                  autoplay
                  autoplayTimeout={6000}
                  responsiveClass
                  margin={10}
                  responsive={{
                    0: {
                      items: 1,
                    },
                    576: {
                      items: 1,
                    },
                    768: {
                      items: 1,
                    },
                    1000: {
                      items: 1,
                    },
                    1200: {
                      items: 3,
                    },
                  }}
                >
                  <div className="review-item">
                    <div className="client-info">
                      <div className="rating">
                        <a href="#/">
                          <i className="fa fa-star" />
                        </a>
                        <a href="#/">
                          <i className="fa fa-star" />
                        </a>
                        <a href="#/">
                          <i className="fa fa-star" />
                        </a>
                        <a href="#/">
                          <i className="fa fa-star" />
                        </a>
                        <a href="#/">
                          <i className="fa fa-star" />
                        </a>
                      </div>
                      <p>
                        This guide provided me with a deeper understanding of
                        the current state of the environment and how our actions
                        can improve them. It's a must-read for anyone looking to
                        align their values with their goals.
                      </p>
                    </div>
                    <div className="client-desc d-flex align-items-center">
                      <div className="client-text">
                        <h5>James Thompson</h5>
                        <span className="designation">Engineer</span>
                      </div>
                    </div>
                  </div>
                  <div className="review-item">
                    <div className="client-info">
                      <div className="rating">
                        <a href="#/">
                          <i className="fa fa-star" />
                        </a>
                        <a href="#/">
                          <i className="fa fa-star" />
                        </a>
                        <a href="#/">
                          <i className="fa fa-star" />
                        </a>
                        <a href="#/">
                          <i className="fa fa-star" />
                        </a>
                        <a href="#/">
                          <i className="fa fa-star" />
                        </a>
                      </div>
                      <p>
                        Not every Green resource on the web catches my
                        attention, but this one did! An invaluable guide for
                        anyone looking to earn while keeping the environment
                        safe and healthy.
                      </p>
                    </div>
                    <div className="client-desc d-flex align-items-center">
                      <div className="client-text">
                        <h5>Ethel Dorsey</h5>
                        <span className="designation">Financial Advisor</span>
                      </div>
                    </div>
                  </div>
                  <div className="review-item">
                    <div className="client-info">
                      <div className="rating">
                        <a href="#/">
                          <i className="fa fa-star" />
                        </a>
                        <a href="#/">
                          <i className="fa fa-star" />
                        </a>
                        <a href="#/">
                          <i className="fa fa-star" />
                        </a>
                        <a href="#/">
                          <i className="fa fa-star" />
                        </a>
                        <a href="#/">
                          <i className="fa fa-star" />
                        </a>
                      </div>
                      <p>
                        A fantastic resource for green investing. Breaks down
                        complex concepts and offers actionable advice for making
                        a positive impact.
                      </p>
                    </div>
                    <div className="client-desc d-flex align-items-center">
                      <div className="client-text">
                        <h5>Sarah Johnson</h5>
                        <span className="designation">Environmentalist</span>
                      </div>
                    </div>
                  </div>
                  <div className="review-item">
                    <div className="client-info">
                      <div className="rating">
                        <a href="#/">
                          <i className="fa fa-star" />
                        </a>
                        <a href="#/">
                          <i className="fa fa-star" />
                        </a>
                        <a href="#/">
                          <i className="fa fa-star" />
                        </a>
                        <a href="#/">
                          <i className="fa fa-star" />
                        </a>
                        <a href="#/">
                          <i className="fa fa-star" />
                        </a>
                      </div>
                      <p>
                        This guide taught me how to earn some bucks without
                        harming the environment. Having a green portfolio is one
                        of my best decisions yet.
                      </p>
                    </div>
                    <div className="client-desc d-flex align-items-center">
                      <div className="client-text">
                        <h5>Michael Anderson</h5>
                        <span className="designation">Business owner</span>
                      </div>
                    </div>
                  </div>
                  <div className="review-item">
                    <div className="client-info">
                      <div className="rating">
                        <a href="#/">
                          <i className="fa fa-star" />
                        </a>
                        <a href="#/">
                          <i className="fa fa-star" />
                        </a>
                        <a href="#/">
                          <i className="fa fa-star" />
                        </a>
                        <a href="#/">
                          <i className="fa fa-star" />
                        </a>
                        <a href="#/">
                          <i className="fa fa-star" />
                        </a>
                      </div>
                      <p>
                        A simple, clear, and practical guide on Green Investing.
                        It's a great read for anyone looking to achieve
                        sustainable wealth.
                      </p>
                    </div>
                    <div className="client-desc d-flex align-items-center">
                      <div className="client-text">
                        <h5>Mia Johnson</h5>
                        <span className="designation">Eco-entrepreneur</span>
                      </div>
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </section>

        <section className="faq section-padding bg-grey pt-0" id="faq">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="section-heading center-heading text-center mb-60">
                  <h3 className="heading-title">Frequently Asked Questions</h3>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div
                  className="panel-group"
                  id="accordion"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="headingOne">
                      <h4 className="panel-title">
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapseOne"
                          aria-controls="collapseOne"
                        >
                          <i className="more-less fa fa-plus" />
                          Why do I need to fill out the information requested?
                        </a>
                      </h4>
                    </div>
                    <div
                      id="collapseOne"
                      className="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="headingOne"
                    >
                      <div className="panel-body">
                        We will always keep your information safe! We ask for
                        your information in exchange for our guide in order to:
                        <ul>
                          <li>
                            a. Send information to you that we think may be of
                            interest to you via email or other means
                          </li>
                          <li>
                            b. Ensure our guide aligns with your interests and
                            goals in green investing.
                          </li>
                          <li>
                            c. Helps us tailor recommendations and insights that
                            specifically fit your needs and goals in building
                            wealth.
                          </li>
                          <li>
                            d. Helps us understand you better and comply with
                            any legal or regulatory requirements.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="headingTwo">
                      <h4 className="panel-title">
                        <a
                          className="collapsed"
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapseTwo"
                          aria-controls="collapseTwo"
                        >
                          <i className="more-less fa fa-plus" />
                          Is this really free?
                        </a>
                      </h4>
                    </div>
                    <div
                      id="collapseTwo"
                      className="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="headingTwo"
                    >
                      <div className="panel-body">
                        Absolutely! We're sharing some information that we know
                        you'll find useful.
                      </div>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="headingThree">
                      <h4 className="panel-title">
                        <a
                          className="collapsed"
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapseThree"
                          aria-controls="collapseThree"
                        >
                          <i className="more-less fa fa-plus" />
                          How does this guide help me?
                        </a>
                      </h4>
                    </div>
                    <div
                      id="collapseThree"
                      className="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="headingThree"
                    >
                      <div className="panel-body">
                        The 'Green Investing 101' guide helps you by providing
                        beginner-friendly guidance on green investing, allowing
                        you to learn how to grow wealth while making positive
                        environmental and social impacts. It offers insights,
                        tips and strategies to get started in green investing,
                        empowering you to align your financial goals with your
                        values and contribute to a more sustainable future.
                      </div>
                    </div>
                  </div>
                  <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="headingFour">
                      <h4 className="panel-title">
                        <a
                          className="collapsed"
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapseFour"
                          aria-controls="collapseFour"
                        >
                          <i className="more-less fa fa-plus" />
                          Does reading this guide have the potential to help me
                          make money?
                        </a>
                      </h4>
                    </div>
                    <div
                      id="collapseFour"
                      className="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="headingFour"
                    >
                      <div className="panel-body">
                        While reading 'Green Investing 101', you can gain
                        valuable knowledge about green investing which might
                        help you make smart investment decisions. However,
                        earning money directly from reading the guide depends on
                        how you apply the information and invest your money
                        wisely in real-life situations.
                      </div>
                    </div>
                  </div>

                  <div className="mt-5 text-center">
                    <button
                      className="btn btn-main-2"
                      onClick={onButtonClicked}
                    >
                      Get Your Free Guide
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="fixed-btm-top">
          <a href="#top-header" className="js-scroll-trigger scroll-to-top">
            <i className="fa fa-angle-up" />
          </a>
        </div>
      </div>
    </>
  );
};

export default Home;
